import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QuoteSheet from "./quoteSheet";
import ModelPicker from "./modelPicker";
import BasePicker from "./basePicker";

export default function Home() {
  const [countM, setCountM] = useState(1);
  const [countB, setCountB] = useState(1);
  const [subTotalsM, setSubTotalsM] = useState([]);
  const [subTotalsB, setSubTotalsB] = useState([]);
  const [quantityM, setQuantityM] = useState([]);
  const [quantityB, setQuantityB] = useState([]);
  const [modelCubeArr, setModelCubeArr] = useState([]);
  const [baseCubeArr, setBaseCubeArr] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [baseData, setBaseData] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [orderNumber, setOrderNumber] = useState("");

  function modelArrTotal(boxIdx, boxSubTotal) {
    const copyArrTotalsM = Array.from(subTotalsM);
    copyArrTotalsM[boxIdx] = boxSubTotal;
    setSubTotalsM(copyArrTotalsM);
  }

  function modelArrQuantity(boxIdx, boxQuantity) {
    const copyArrQuantitiesM = Array.from(quantityM);
    copyArrQuantitiesM[boxIdx] = boxQuantity;
    setQuantityM(copyArrQuantitiesM);
  }

  function getModelData(boxIdx, boxData, modelDescription) {
    const copyArrDataM = Array.from(modelData);
    copyArrDataM[boxIdx] = [boxData, modelDescription];
    setModelData(copyArrDataM);
  }

  // function was replaced to split cube totaling into models + bases
  // function cubesArrTotal(boxIdx, boxCubes) {
  //   const copyItemCubesArr = Array.from(itemCubesArr);
  //   copyItemCubesArr[boxIdx] = boxCubes;
  //   setItemCubesArr(copyItemCubesArr);
  // }

  function modelCubeTotal(boxIdx, boxCubes) {
    const copyModelCubeArr = Array.from(modelCubeArr);
    copyModelCubeArr[boxIdx] = boxCubes;
    setModelCubeArr(copyModelCubeArr);
  }

  function baseArrTotal(boxIdx, boxSubTotal) {
    const copyArrTotalsB = Array.from(subTotalsB);
    copyArrTotalsB[boxIdx] = boxSubTotal;
    setSubTotalsB(copyArrTotalsB);
  }

  function baseArrQuantity(boxIdx, boxQuantity) {
    const copyArrQuantitiesB = Array.from(quantityB)
    copyArrQuantitiesB[boxIdx] = boxQuantity;
    setQuantityB(copyArrQuantitiesB);
  }

  function getBaseData(boxIdx, boxData, baseDescription) {
    const copyArrDataB = Array.from(baseData);
    copyArrDataB[boxIdx] = [boxData, baseDescription];
    setBaseData(copyArrDataB);
  }

  function baseCubeTotal(boxIdx, boxCubes) {
    const copyBaseCubeArr = Array.from(baseCubeArr);
    copyBaseCubeArr[boxIdx] = boxCubes;
    setBaseCubeArr(copyBaseCubeArr);
  }

  function handleCustomerData(e) {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value
    });
  }

  const length = 7;
  function createOrderNumber(length) {
    const tempOrderNumber = "OW" + Math.floor(Math.pow(10, length-1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length-1) - 1));
    setOrderNumber(tempOrderNumber);
  }

  const navigate = useNavigate();
  // need to add cube to review page w/ logic to stop advance if total > 2694

  const handleReviewClick = () => {
    if (modelData.length < 1 
      // || Object.keys(customerData).length < 11
    ) {
      // change to modal/some other popup rather than alert
      window.alert('Please complete the form before continuing.');
    } else if (modelData.length >= 1 && baseData.length < 1) {
      navigate("/review", { state: {
        countM,
        orderNumber,
        customerData, 
        modelData,
        quantityM, 
        subTotalsM,
        gTotal,
        cTotal,
        modelCubeArr,
        qTotal
      }});
    } else {
      navigate("/review", { state: {
        countM,
        countB,
        orderNumber,
        customerData, 
        modelData,
        baseData,
        quantityM, 
        quantityB, 
        subTotalsM, 
        subTotalsB,
        gTotal,
        cTotal,
        modelCubeArr,
        baseCubeArr,
        qTotal
      }});
    }
  }

  useEffect(() => {
    createOrderNumber(length);

    // FOR TESTING ONLY
    fetch(`https://tempo-order-writer.vercel.app/echo`)
    .then((res) => res.json())
    .then(data => {
      console.log("TESTING SERVER GET", data);
    })
    .catch(console.error);

    fetch(`https://tempo-order-writer.vercel.app/postTest`, {
      method: "POST",
      body: JSON.stringify({
        info: "foo",
        data: ["bar", "bar2", "bar3"]
      })
    })
    .then((res) => res.json())
    .then(data => {
      console.log("TESTING SERVER POST", data);
    })
    .catch(console.error);
    // END TESTING

  }, []);

  const gTotal = (subTotalsM.reduce((a, b) => a + b, 0) + subTotalsB.reduce((a, b) => a + b, 0));
  // evaluate formula
  const cTotal = (modelCubeArr.reduce((a, b) => a + b, 0) + baseCubeArr.reduce((a, b) => a + b, 0));
  const qTotal = (quantityM.reduce((a, b) => a + b, 0) + quantityB.reduce((a, b) => a + b, 0));
  const gTotalDisplay = gTotal.toFixed(2);
  const cTotalDisplay = cTotal.toFixed(2);
  console.log(subTotalsM);
  console.log(gTotal);
  console.log(qTotal);

  return (
    <div className="App">
      {/* form element? worth exploring */}
      <QuoteSheet handleFormChange={(e) => handleCustomerData(e)} customer={customerData} order={orderNumber}/>
      {Array(countM).fill(0).map((_, idx) => {
        return <ModelPicker
        key={idx}
        updateSubTotals={(boxSubTotal) => {modelArrTotal(idx, boxSubTotal)}}
        updateQuantities={(boxQuantity) => {modelArrQuantity(idx, boxQuantity)}}
        updateModelData={(boxData, chosenModelDesc) => {getModelData(idx, boxData, chosenModelDesc)}}
        updateCubesTotal={(boxCubeCount) => {modelCubeTotal(idx, boxCubeCount)}}
        />
      })}
      <button type="submit" onClick={() => setCountM((prevCount) => prevCount + 1)}>Add New</button>
      {Array(countB).fill(0).map((_, idx) => {
        return <BasePicker
        key={idx}
        updateSubTotals={(boxSubTotal) => {baseArrTotal(idx, boxSubTotal)}}
        updateQuantities={(boxQuantity) => {baseArrQuantity(idx, boxQuantity)}}
        // check whether new data is included in baseData array
        updateBaseData={(boxData, chosenBaseDesc) => {getBaseData(idx, boxData, chosenBaseDesc)}}
        updateCubesTotal={(boxCubeCount) => {baseCubeTotal(idx, boxCubeCount)}}
        />
      })}
      <button onClick={() => setCountB((prevCount) => prevCount + 1)}>Add New</button>
      <div className="cost-container">
        {/* layout needs more work */}
        <div className="total-cost">
          <label htmlFor="total">Total Price:</label>
          <input 
            type="text"
            name="total"
            id="total"
            value={gTotalDisplay}
            readOnly
            />
          <label htmlFor="">Total Cubes:</label>
            <p className="cube-calc"><strong>{cTotalDisplay}</strong>/2694 ft³</p>
        </div>
      </div>
      <div className="submit-order">
        <button onClick={() => handleReviewClick()}>Finish and Review</button>
      </div>
      <div className="contact">
        <p>Please reach out with any questions, comments, or concerns. Thank you.</p>
        <div className="contact-els">
        <button onClick={() => window.location = 'mailto:info@tempoleisure.com'} className="contact-btn">Email Us</button>
        <p>or get in touch <a href="https://tempopatio.com/contact" target="_blank" rel="noreferrer" className="external">on the web</a>.</p>
        </div>
      </div>
      <div className="attributions">
        <p>© Tempo Patio 2023 </p>
        <p>Version 1.0</p>
      </div>
    </div>
  );
}

